import {Route, Routes} from "react-router-dom";
import UploadTransactions from "../pages/bank-transactions/upload-transactions";
import BankTransactionsList from "../pages/bank-transactions/bank-transactions-list";

function BankTransactionsRoutes() {
    return (
        <Routes>
            <Route path="upload" element={<UploadTransactions />} />
            <Route path="" element={<BankTransactionsList />} />
        </Routes>
    );
}

export default BankTransactionsRoutes;
