import {useCallback, useEffect, useState} from "react";
import {AddIcon, EditIcon, RemoveIcon} from "../../icons/icons";
import {Button, ButtonTypes, LinkButton} from "../../components/button/button";
import {customersService} from "../../services/customers";
import {NewDataList} from "../../components/data-list/data-list";
import "./customers-list.scss";

function CustomersList() {
    const [customersData, setCustomersData] = useState(null);
    const [query, setQuery] = useState("");

    const fetchData = useCallback(async ({page, pageSize, orderBy} = {page: 1, pageSize: 10, orderBy: "name"}) => {
        setCustomersData(await customersService.search({page, pageSize, query, orderBy}));
    }, [query]);

    useEffect(() => {
        fetchData();
    }, [fetchData, query]);

    const handleFilterChange = async (e) => {
        setQuery(e.target.value);
    };

    const handleDeleteCustomer = async (id) => {
        await customersService.delete(id);
        await fetchData({page: customersData.paging.page, pageSize: customersData.paging.pageSize, orderBy: customersData.orderBy});
    }

    return (
        <>
            <div className="page-header">
                <h2>Customers</h2>
                <div className="page-header-actions">
                    <LinkButton to={"/customers/new"} buttonType={ButtonTypes.Primary} icon={AddIcon}>
                        Create
                    </LinkButton>
                </div>
            </div>
            {customersData &&
                <NewDataList configuration={{
                    data: customersData,
                    onPageChanged: fetchData,
                    onPageSizeChanged: fetchData,
                    onSortChanged: fetchData,
                    name: "customers",
                    filters: [
                        {name: "Filter", element: <input name={"filter"} type={"text"} onChange={handleFilterChange}/>}
                    ],
                    columnDefinitions: [{
                        name: "name",
                        title: "Name",
                        render: (value) => value || "/",
                        canSort: true
                    }, {
                        name: "vatNumber",
                        title: "VAT",
                        render: (value) => value || "/",
                        canSort: true
                    }, {
                        name: "defaultUnitPrice",
                        title: "Hourly rate",
                        render: (value) =>  value ? value : "/",
                        canSort: true
                    }],
                    rowActions: [
                        (rowData) => <LinkButton to={`/customers/${rowData._id}`} icon={EditIcon}/>,
                        (rowData) => <Button onClick={() => handleDeleteCustomer(rowData._id)} icon={RemoveIcon}/>
                    ]
                }}/>
            }
        </>
    );
}

export default CustomersList;
