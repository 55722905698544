import "./file-upload.scss";

export function FileUpload({selectedFile, setSelectedFile}) {
    const onFileChange = event => {
        setSelectedFile(event.target.files[0]);
    };
    const sizeScales = ["K", "M", "G", "T"];
    const calculateSize = (fileSize) => {
        let sizeScale = -1;
        while(fileSize > 1024 || sizeScale > sizeScales.length - 1) {
            fileSize = fileSize / 1024;
            sizeScale++;
        }
        return `${fileSize.toFixed(2)}${sizeScale > -1 ? sizeScales[sizeScale] : ""}B`;
    }
    return (
        <div className={"file-upload"}>
            <input type="file" onChange={onFileChange}/>
            {selectedFile &&
                <div>
                    <h4>{selectedFile.name}</h4>
                    Type: {selectedFile.type}<br />
                    Size: {calculateSize(selectedFile.size)}
                </div>
            }
        </div>
    )
}
