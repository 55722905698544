import { useKeycloak } from "@react-keycloak/web";
import AccessDenied from "../pages/access-denied/access-denied";

const PrivateRoute = ({ children, role }) => {
    const { keycloak } = useKeycloak();

    const isLoggedIn = keycloak.authenticated;

    if(!isLoggedIn) {
        return keycloak.login();
    }
    if(role) {
        return keycloak.realmAccess.roles.indexOf(role) >= 0 ? children : <AccessDenied />;
    }
    return children;
};

export default PrivateRoute;
