import {Invoice} from "../models/invoice";
import {httpService} from "./http-service";

class InvoicesService {
    get _apiUrl() {
        return `${process.env.REACT_APP_INVOCING_API_URL}/api/invoices`;
    }
    async search(filters = {page: 1, pageSize: 10, orderBy: "-number", filter: ""}) {
        const queryParams = this.getQueryParams(filters);
        const url = new URL(this._apiUrl);
        url.search = queryParams.toString();

        const invoiceResults = await httpService.get(url);
        return {
            ...invoiceResults,
            results: invoiceResults.results.map(i => new Invoice(i)),
        };
    }

    async getByNumber(invoiceNumber) {
        const invoice = await httpService.get(`${this._apiUrl}/${invoiceNumber}`);
        return new Invoice(invoice);
    }

    async create(invoice) {
        return await httpService.postJson(this._apiUrl, invoice);
    }

    async update(invoice) {
        return await httpService.put(`${this._apiUrl}/${invoice._id}`, invoice);
    }

    async delete(invoiceId) {
        return await httpService.delete(`${this._apiUrl}/${invoiceId}`);
    }

    async generateFile(invoiceId, templateName) {
        return await httpService.postJson(`${this._apiUrl}/${invoiceId}/generate-document`, {
            templateName
        });
    }

    async downloadFiltered(filters = {filter: ""}) {
        const queryParams = this.getQueryParams(filters);
        const url = new URL(`${this._apiUrl}/download-multiple`);
        url.search = queryParams.toString();
        return await httpService.download(url);
    }

    getQueryParams(params ) {
        const queryParams = new URLSearchParams();
        Object.keys(params).forEach(key => {
            if(params[key]) {
                queryParams.set(key, params[key]);
            }
        });
        return queryParams;
    }
}

const instance = new InvoicesService();
export {instance as invoicesService};
