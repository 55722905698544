import React, {useState} from "react";
import {AddIcon, RemoveIcon} from "../../icons/icons";
import {Button, ButtonTypes} from "../button/button";
import {Customer} from "../../models/customer";
import {FormGroup} from "../form-group/form-group";
import {customersService} from "../../services/customers";

export function CustomerForm({customer, onCancel, onDelete, onSaved, onSaveError}) {
    const [newAddressLine, setNewAddressLine] = useState("");
    const [addressLines, setAddressLines] = useState(customer?.addressLines || []);
    const [errorMessage, setErrorMessage] = useState("");
    const [name, setName] = useState(customer?.name || "");
    const [vatNumber, setVatNumber] = useState(customer?.vatNumber || "");
    const [vatApplicable, setVatApplicable] = useState(customer ? customer.vatApplicable : true);
    const [defaultUnitPrice, setDefaultUnitPrice] = useState(customer?.defaultUnitPrice || 0);
    const [defaultUnit, setDefaultUnit] = useState(customer?.defaultUnit || "Uur");
    const removeAddressLine = (idx) => {
        addressLines.splice(idx, 1);
        setAddressLines([...addressLines]);
    }
    const addAddressLine = () => {
        addressLines.push(newAddressLine);
        setNewAddressLine("");
        setAddressLines([...addressLines]);
    }
    const handleInput = (set, e) => {
        set(e.target.value);
    };
    const handleCheckboxInput = (set, e) => {
        set(e.target.checked);
    };
    const handleAddressLineChange = (newValue, idx) => {
        addressLines[idx] = newValue;
    }
    const handleSaveClicked = async (e) => {
        try {
            let newAddressLines = [...addressLines];
            if (newAddressLine) {
                newAddressLines.push(newAddressLine);
            }
            const customerToSave = new Customer({
                _id: customer?._id,
                name,
                vatNumber,
                vatApplicable,
                defaultUnitPrice,
                defaultUnit,
                addressLines: newAddressLines
            });
            console.log("Saving customer", customerToSave);
            const response = await customersService.saveCustomer(customerToSave);
            if (response) {
                clearForm();
                onSaved && onSaved(e, customerToSave);
            } else {
                onSaveError && onSaveError(e, response);
            }
        } catch (err) {
            if (err.validationErrors?.length > 0) {
                setErrorMessage(err.validationErrors[0].message)
            } else {
                setErrorMessage(err.message);
            }
        }
    };
    const handleCancelClicked = (e) => {
        onCancel && onCancel(e);
    };
    const handleDeleteClicked = async (e) => {
        await customersService.delete(customer._id)
        onDelete && onDelete(e);
    }

    function clearForm() {
        setAddressLines([]);
        setNewAddressLine("");
        setName("");
        setVatApplicable(true);
    }

    return (
        <form>
            {errorMessage &&
                <div className={"form-error"}>
                    {errorMessage}
                </div>
            }
            <div className={"form-body"}>
                <FormGroup label={"Name"} id={"name"} input={
                    <input id="name" value={name} onChange={(e) => handleInput(setName, e)} type="text"/>
                }/>
                <FormGroup label={"VAT"} id={"vatNumber"} input={
                    <input id="vatNumber" value={vatNumber} onChange={(e) => handleInput(setVatNumber, e)} type="text"/>
                }/>
                <FormGroup label={"VAT applicable"} id={"vatApplicable"} input={
                    <input id="vatApplicable" checked={vatApplicable}
                           onChange={(e) => handleCheckboxInput(setVatApplicable, e)} type="checkbox"/>
                }/>
                <FormGroup label={"Hourly rate"} id={"defaultUnitPrice"} input={
                    <input id="defaultUnitPrice" value={defaultUnitPrice}
                           onChange={(e) => handleInput(setDefaultUnitPrice, e)} type="text"/>
                }/>
                <FormGroup label={"Default unit"} id={"defaultUnit"} input={
                    <input id="defaultUnit" value={defaultUnit}
                           onChange={(e) => handleInput(setDefaultUnit, e)} type="text"/>
                }/>
                {addressLines.map((line, idx) => (
                    <FormGroup key={idx} label={`Address line ${idx + 1}`} id={`address-line-${idx}`} input={
                        <input type="text" id={`address-line-${idx}`} defaultValue={line}
                               onChange={(e) => handleAddressLineChange(e.target.value, idx)}/>
                    } action={<Button icon={RemoveIcon}/>} onAction={() => removeAddressLine(idx)}/>
                ))}
                <FormGroup label={`Address line`} id={`address-line`} input={
                    <input type="text" id={`address-line`} value={newAddressLine}
                           onChange={(e) => handleInput(setNewAddressLine, e)}/>
                } action={<Button icon={AddIcon}/>} onAction={addAddressLine}/>
            </div>
            <div className={"form-actions"}>
                <Button buttonType={ButtonTypes.Text} onClick={handleCancelClicked}>
                    Cancel
                </Button>
                {customer?._id &&
                    <Button buttonType={ButtonTypes.Text} onClick={handleDeleteClicked}>
                        Delete
                    </Button>
                }
                <Button buttonType={ButtonTypes.Primary} onClick={handleSaveClicked}>
                    {customer ? "Save" : "Create"}
                </Button>
            </div>
        </form>
    );
}
