import {Route, Routes} from "react-router-dom";
import InvoiceDetails from "../pages/invoices/invoice-details";
import InvoicesList from "../pages/invoices/invoices-list";
import {CreateInvoice} from "../pages/invoices/create-invoice";

function InvoicesRoutes() {
    return (
        <Routes>
            <Route path="new" element={<CreateInvoice />} />
            <Route path=":invoiceNumber" element={<InvoiceDetails />} />
            <Route path="" element={<InvoicesList />} />
        </Routes>
    );
}

export default InvoicesRoutes;
