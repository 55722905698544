import React, {useCallback, useEffect, useState} from "react";
import {invoicesService} from "../../services/invoices";
import {AddIcon, DocumentIcon, DownloadIcon, EditIcon, EyeIcon} from "../../icons/icons";
import {Button, ButtonTypes, LinkButton} from "../../components/button/button";
import {templatesService} from "../../services/templates";
import {Modal, ModalSizes} from "../../components/modal/modal";
import {NewDataList} from "../../components/data-list/data-list";
import {Document, Page} from "react-pdf/dist/umd/entry.webpack";
import {filesService} from "../../services/files";
import "./invoices-list.scss";
import DateColumn from "../../components/column-types/date";
import CurrencyColumn from "../../components/column-types/currency";

function InvoicesList() {
    const [generateFileErrorMessage, setGenerateFileErrorMessage] = useState(null);
    const [selectedInvoiceId, setSelectedInvoiceId] = useState();
    const [showTemplateModal, setShowTemplateModal] = useState(false);
    const [invoicesData, setInvoicesData] = useState(null);
    const [filter, setFilter] = useState("");
    const [dateFrom, setDateFrom] = useState(null);
    const [dateUntil, setDateUntil] = useState(null);
    const [templates, setTemplates] = useState();
    const [pdf, setPdf] = useState(null);
    const [pdfFile, setPdfFile] = useState(null);
    const [numPages, setNumPages] = useState(null);

    const fetchData = useCallback(async ({page, pageSize, orderBy} = {page: 1, pageSize: 10, orderBy: "-number"}) => {
        const data = await invoicesService.search({
            page,
            pageSize,
            orderBy,
            filter,
            dateFrom,
            dateUntil
        });
        setInvoicesData(data);
    }, [filter, dateFrom, dateUntil]);

    const fetchTemplates = useCallback(async () => {
        const templates = await templatesService.getAll();
        setTemplates(templates);
    }, []);

    useEffect(() => {
        fetchData();
        fetchTemplates();
    }, [fetchData, fetchTemplates]);

    const onDocumentLoadSuccess = ({numPages}) => {
        setNumPages(numPages);
    };

    const handleFilterChange = async (e) => {
        setFilter(e.target.value);
    };

    const handleDateFromChange = async (e) => {
        setDateFrom(e.target.value);
    };

    const handleDateUntilChange = async (e) => {
        setDateUntil(e.target.value);
    };

    const handleGenerateFile = async (invoiceId) => {
        setSelectedInvoiceId(invoiceId);
        setShowTemplateModal(true);
    };

    const handleTemplateClick = async (template) => {
        try {
            setGenerateFileErrorMessage(null);
            await invoicesService.generateFile(selectedInvoiceId, template);
            await fetchData();
            setShowTemplateModal(false);
        } catch(err) {
            setGenerateFileErrorMessage(err.message);
        }
    }

    const handleSelectTemplateClosed = () => {
        setShowTemplateModal(false);
        setGenerateFileErrorMessage(null);
    }

    async function fetchPdf(fileId) {
        if (fileId) {
            const pdfFile = await filesService.download(fileId);
            const buffer = await pdfFile.blob.arrayBuffer();
            return {pdfFile, buffer};
        }
    }

    const handleDownloadMultiple = async () => {
        const zipFile = await filesService.downloadMultiple(invoicesData.fileIds, "invoices");
        filesService.downloadFile(zipFile);
    }

    const handleViewPdf = async (fileId) => {
        const {pdfFile, buffer} = await fetchPdf(fileId);
        setPdf(buffer);
        setPdfFile(pdfFile);
    }

    const handleDirectDownload = async (fileId) => {
        const {pdfFile} = await fetchPdf(fileId);
        savePdf(pdfFile);
    }

    const handleDownload = async () => {
        savePdf(pdfFile);
        closePdfView();
    }

    const closePdfView = () => {
        setPdf(null);
        setPdfFile(null);
    }

    function savePdf(pdfFile) {
        filesService.downloadFile(pdfFile);
    }

    return (
        <>
            <div className="page-header">
                <h2>Invoices</h2>
                <div className="page-header-actions">
                    <LinkButton to={"/invoices/new"} buttonType={ButtonTypes.Primary} icon={AddIcon}>
                        Create
                    </LinkButton>
                </div>
            </div>
            {invoicesData &&
                <NewDataList configuration={{
                    name: "invoices",
                    data: invoicesData,
                    onPageChanged: fetchData,
                    onPageSizeChanged: fetchData,
                    onSortChanged: fetchData,
                    columnDefinitions: [{
                        name: "number",
                        title: "Number",
                        render: (value) => value || "/",
                        canSort: true
                    }, {
                        name: "date",
                        title: "Date",
                        render: (value) => <DateColumn date={value} />,
                        canSort: true
                    }, {
                        name: "customer",
                        title: "Customer",
                        render: (value) => value?.name || "/",
                        canSort: true
                    }, {
                        name: "totalPrice",
                        title: "Total",
                        render: (value) => <CurrencyColumn value={value} />,
                        canSort: true
                    }],
                    headerActions: [
                        (data) => <>
                            {data?.some(t => t.fileId) &&
                                <Button onClick={handleDownloadMultiple} icon={DownloadIcon}/>}
                        </>
                    ],
                    rowActions: [
                        (rowData) => <>{rowData.fileId &&
                            <Button onClick={() => handleDirectDownload(rowData.fileId)} icon={DownloadIcon}/>}</>,
                        (rowData) => <>{rowData.fileId && <Button onClick={() => handleViewPdf(rowData.fileId)} icon={EyeIcon}/>}</>,
                        (rowData) => <Button icon={DocumentIcon} onClick={() => handleGenerateFile(rowData._id)}/>,
                        (rowData) => <LinkButton to={`/invoices/${rowData.number}`} icon={EditIcon} />,
                    ],
                    filters: [
                        {name: "Filter", element: <input name={"filter"} type={"text"} onChange={handleFilterChange}/>},
                        {name: "Date from", element: <input name={"dateFrom"} type={"date"} onChange={handleDateFromChange}/>},
                        {name: "Date until", element: <input name={"dateUntil"} type={"date"} onChange={handleDateUntilChange}/>}
                    ]
                }} />
            }
            {pdf &&
                <Modal title={"Invoice"} onClose={closePdfView} size={ModalSizes.max} actions={[
                    <Button icon={DownloadIcon} onClick={() => handleDownload()}/>
                ]}>
                    <Document file={pdf} onLoadSuccess={onDocumentLoadSuccess}>
                        {Array.from(
                            new Array(numPages),
                            (el, index) => (
                                <Page
                                    key={`page_${index + 1}`}
                                    pageNumber={index + 1}
                                />
                            ),
                        )}
                    </Document>
                </Modal>
            }
            {
                showTemplateModal &&
                <Modal title={"Select template"} onClose={handleSelectTemplateClosed}>
                    {generateFileErrorMessage && <div className={"form-error"}>{generateFileErrorMessage}</div>}
                    {templates?.map(t =>
                        <div key={t.name}>
                            <Button onClick={() => handleTemplateClick(t.name)} >{t.name}</Button>
                        </div>
                    )}
                </Modal>
            }
        </>
    );
}

export default InvoicesList;
