import {httpService} from "./http-service";
import {BankTransaction} from "../models/bank-transaction";

class BankTransactionsService {
    get _apiUrl() {
        return `${process.env.REACT_APP_INVOCING_API_URL}/api/bank-transactions`;
    }

    async getAll(filters = {page: 1, pageSize: 10, orderBy: "-date", filter: ""}) {
        const queryParams = this.getQueryParams(filters);
        const url = new URL(this._apiUrl);
        url.search = queryParams.toString();

        const transactionResults = await httpService.get(url);
        return {
            ...transactionResults,
            results: transactionResults.results.map(i => new BankTransaction(i)),
        };
    }

    async upload(file) {
        return await httpService.uploadFile(`${this._apiUrl}/upload`, file);
    }

    async linkInvoice(transactionId, file, name) {
        return await httpService.uploadFile(`${this._apiUrl}/${transactionId}/link-invoice`, file, name);
    }

    async unlinkInvoice(transactionId, fileId) {
        return await httpService.postJson(`${this._apiUrl}/${transactionId}/unlink-invoice`, {fileId});
    }

    async download(transactionId) {
        return await httpService.download(`${this._apiUrl}/${transactionId}/download`);
    }

    async markHandled(transactionId) {
        return await httpService.postJson(`${this._apiUrl}/${transactionId}/mark-handled`, {});
    }

    async downloadAll(filters = {filter: ""}) {
        const queryParams = this.getQueryParams(filters);
        const url = new URL(`${this._apiUrl}/download-multiple`);
        url.search = queryParams.toString();
        return await httpService.download(url);
    }

    getQueryParams({page, pageSize, orderBy, filter, dateFrom, dateUntil}) {
        const queryParams = new URLSearchParams({
            page, pageSize, orderBy, filter
        });
        if(dateFrom) {
            queryParams.set("dateFrom", dateFrom);
        }
        if(dateUntil) {
            queryParams.set("dateUntil", dateUntil);
        }
        return queryParams;
    }
}

const instance = new BankTransactionsService();
export { instance as bankTransactionsService };
