import {httpService} from "./http-service";
import {Template} from "../models/template";

class TemplatesService {
    get _apiUrl() {
        return `${process.env.REACT_APP_INVOCING_API_URL}/api/templates`;
    }
    async getAll() {
        const templates = await httpService.get(this._apiUrl);
        return templates.map(t => new Template(t));
    }
}

const instance = new TemplatesService();
export { instance as templatesService };
