import './App.scss';

import {ReactKeycloakProvider} from "@react-keycloak/web";
import {AppRouter} from "./routes/router";
import {Header} from "./components/header";
import keycloak from "./keycloak";
import {BrowserRouter} from "react-router-dom";

function App() {
    return (
        <ReactKeycloakProvider authClient={keycloak}>
            <BrowserRouter>
                <div className="app">
                    <Header/>
                    <div className="page">
                        <AppRouter/>
                    </div>
                </div>
            </BrowserRouter>
        </ReactKeycloakProvider>
    );
}

export default App;
