import React, {useCallback, useEffect, useState} from "react";
import {Document, Page} from "react-pdf/dist/umd/entry.webpack";
import {
    AddIcon,
    ArrowLeftIcon,
    ArrowRightIcon,
    CheckedCheckbox,
    DownloadIcon,
    EyeIcon,
    RemoveIcon, UncheckedCheckbox
} from "../../icons/icons";
import {Button, ButtonTypes, LinkButton} from "../../components/button/button";
import {bankTransactionsService} from "../../services/bank-transactions";
import {Modal, ModalSizes} from "../../components/modal/modal";
import {LinkBankTransactionForm} from "../../components/forms/link-bank-transaction";
import {NewDataList} from "../../components/data-list/data-list";
import {filesService} from "../../services/files";
import "./bank-transactions-list.scss";
import DateColumn from "../../components/column-types/date";
import CurrencyColumn from "../../components/column-types/currency";

function BankTransactionsList() {
    const [selectedTransactionId, setSelectedTransactionId] = useState(null);
    const [transactionsData, setTransactionsData] = useState(null);
    const [filter, setFilter] = useState("");
    const [dateFrom, setDateFrom] = useState(null);
    const [dateUntil, setDateUntil] = useState(null);
    const [pdfContext, setPdfContext] = useState(null);
    const [numPages, setNumPages] = useState(null);

    const fetchData = useCallback(async ({page, pageSize, orderBy} = {page: 1, pageSize: 10, orderBy: "-date"}) => {
        const data = await bankTransactionsService.getAll({
            page,
            pageSize,
            orderBy,
            filter,
            dateFrom,
            dateUntil
        });
        setTransactionsData(data);
    }, [filter, dateFrom, dateUntil]);

    useEffect(() => {
        fetchData();
    }, [fetchData, filter, dateFrom, dateUntil])

    const onDocumentLoadSuccess = ({numPages}) => {
        setNumPages(numPages);
    };

    const handleLinkDocument = (e, transaction) => {
        setSelectedTransactionId(transaction._id);
        e.preventDefault();
    };

    const handleFilterChange = async (e) => {
        setFilter(e.target.value);
    };

    const handleDateFromChange = async (e) => {
        setDateFrom(e.target.value);
    };

    const handleDateUntilChange = async (e) => {
        setDateUntil(e.target.value);
    };

    const handleFormSaved = async () => {
        setSelectedTransactionId(null);
        await fetchData({page: transactionsData.paging.page, pageSize: transactionsData.paging.pageSize, orderBy: transactionsData.orderBy});
    };

    const handleViewPdf = async (transaction) => {
        const {pdfFile, buffer} = await fetchPdf(transaction.fileIds[0]);
        setPdfContext({
            buffer,
            pdfFile,
            transaction,
            fileIndex: 0,
            fileIds: transaction.fileIds
        });
    }

    const handleMarkHandled = async (transaction) => {
        await bankTransactionsService.markHandled(transaction._id);
        await fetchData({page: transactionsData.paging.page, pageSize: transactionsData.paging.pageSize, orderBy: transactionsData.orderBy});
    }

    const handleNextPdf = async () => {
        const {pdfFile, buffer} = await fetchPdf(pdfContext.fileIds[pdfContext.fileIndex + 1]);
        setPdfContext({
            ...pdfContext,
            buffer,
            pdfFile,
            fileIndex: pdfContext.fileIndex + 1,
            fileIds: pdfContext.fileIds
        });
    }

    const handlePreviousPdf = async () => {
        const {pdfFile, buffer} = await fetchPdf(pdfContext.fileIds[pdfContext.fileIndex - 1]);
        setPdfContext({
            ...pdfContext,
            buffer,
            pdfFile,
            fileIndex: pdfContext.fileIndex - 1,
            fileIds: pdfContext.fileIds
        });
    }

    const handleDirectDownload = async (transaction) => {
        if (transaction.fileIds) {
            if(transaction.fileIds.length > 1) {
                const zip = await filesService.downloadMultiple(transaction.fileIds, "transactions");
                filesService.downloadFile(zip);
            } else if(transaction.fileIds.length === 1) {
                const pdf = await filesService.download(transaction.fileIds[0]);
                filesService.downloadFile(pdf);
            }
        }
    }

    async function fetchPdf(fileId) {
        if (fileId) {
            const pdfFile = await filesService.download(fileId);
            const buffer = await pdfFile.blob.arrayBuffer();
            return {pdfFile, buffer};
        }
    }

    const handleDownloadAll = async () => {
        const zipFile = await filesService.downloadMultiple(transactionsData.fileIds);
        filesService.downloadFile(zipFile);
    }

    const handleDownload = async () => {
        filesService.downloadFile(pdfContext.pdfFile);
        closePdfView();
    }

    const handleDelete = async () => {
        await bankTransactionsService.unlinkInvoice(pdfContext.transaction._id, pdfContext.fileIds[pdfContext.fileIndex]);
        closePdfView();
        await fetchData();
    }

    const closePdfView = () => {
        setPdfContext(null);
        setNumPages(null);
    }

    const handleDrop = async (transaction, files) => {
        for(const file of files) {
            await bankTransactionsService.linkInvoice(transaction._id, file, file.name);
        }
        await fetchData({page: transactionsData.paging.page, pageSize: transactionsData.paging.pageSize, orderBy: transactionsData.orderBy});
    }

    return (
        <>
            <div className="page-header">
                <h2>Bank transactions</h2>
                <div className="page-header-actions">
                    <LinkButton to={"/bank-transactions/upload"} buttonType={ButtonTypes.Primary} icon={AddIcon}>
                        Upload
                    </LinkButton>
                </div>
            </div>
            {transactionsData &&
                <NewDataList configuration={{
                    data: transactionsData,
                    onPageChanged: fetchData,
                    onPageSizeChanged: fetchData,
                    onSortChanged: fetchData,
                    name: "transactions",
                    enableDragDrop: true,
                    onRowDrop: handleDrop,
                    className: "",
                    filters: [
                        {name: "Filter", element: <input name={"filter"} type={"text"} onChange={handleFilterChange}/>},
                        {name: "Date from", element: <input name={"dateFrom"} type={"date"} onChange={handleDateFromChange}/>},
                        {name: "Date until", element: <input name={"dateUntil"} type={"date"} onChange={handleDateUntilChange}/>}
                    ],
                    columnDefinitions: [{
                        name: "date",
                        title: "Date",
                        render: (value) => <DateColumn date={value} />,
                        canSort: true
                    }, {
                        name: "description",
                        title: "Description",
                        render: (value) => value || "/",
                        canSort: true
                    }, {
                        name: "amount",
                        title: "Amount",
                        alignRight: true,
                        render: (value) => <CurrencyColumn value={value} />,
                        canSort: true
                    }],
                    headerActions: [
                        (data) => <>
                            {data?.some(t => transactionsData.fileIds?.length > 0) &&
                                <Button onClick={handleDownloadAll} icon={DownloadIcon}/>}
                        </>
                    ],
                    rowActions: [
                        (rowData) => <>{rowData.fileIds?.length > 0 &&
                            <Button onClick={() => handleDirectDownload(rowData)} icon={DownloadIcon}/>}</>,
                        (rowData) => <>{rowData.fileIds?.length > 0 &&
                            <Button onClick={() => handleViewPdf(rowData)} icon={EyeIcon}/>}</>,
                        (rowData) => <>{rowData.fileIds?.length <= 0 && (
                            rowData.isHandled ?
                                <Button onClick={() => handleMarkHandled(rowData)} icon={CheckedCheckbox}/> :
                                <Button onClick={() => handleMarkHandled(rowData)} icon={UncheckedCheckbox}/>
                        )}</>,
                        (rowData) => <Button onClick={(e) => handleLinkDocument(e, rowData)} icon={AddIcon}/>
                    ],
                    dataRowClassName: (rowData) => rowData.isHandled || rowData.fileIds?.length > 0 ? "handled" : ""
                }}/>
            }
            {selectedTransactionId &&
                <Modal title={"Upload invoice"} onClose={() => setSelectedTransactionId(null)}>
                    <LinkBankTransactionForm transactionId={selectedTransactionId}
                                             onCancel={() => setSelectedTransactionId(null)} onSaved={handleFormSaved}/>
                </Modal>
            }
            {pdfContext &&
                <Modal title={pdfContext.pdfFile.fileName} onClose={closePdfView} size={ModalSizes.max} actions={[
                    <Button icon={DownloadIcon} onClick={() => handleDownload()}/>,
                    <Button icon={RemoveIcon} onClick={() => handleDelete()}/>,
                    <Button icon={ArrowLeftIcon} onClick={() => handlePreviousPdf()} disabled={pdfContext.fileIndex === 0}/>,
                    <Button icon={ArrowRightIcon} onClick={() => handleNextPdf()} disabled={pdfContext.fileIndex === pdfContext.fileIds.length -1}/>,
                ]}>
                    <Document file={pdfContext.buffer} onLoadSuccess={onDocumentLoadSuccess}>
                        {Array.from(
                            new Array(numPages),
                            (el, index) => (
                                <Page
                                    key={`page_${index + 1}`}
                                    pageNumber={index + 1}
                                />
                            ),
                        )}
                    </Document>
                </Modal>
            }
        </>
    );
}

export default BankTransactionsList;
