function DateColumn({date, emptyValue}) {
    if(date) {
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, "00");
        const day = date.getDate().toString().padStart(2, "00");
        return `${day}-${month}-${year}`;
    } else {
        return emptyValue || "/";
    }
}

export default DateColumn;
