import {Button} from "../button/button";
import {ArrowLeftIcon, ArrowRightIcon} from "../../icons/icons";

/**
 *
 * @param {{page: number, totalPages: number, pageSize: number, total: number}} paging
 * @param onPreviousPageClick
 * @param onNextPageClick
 * @param onPageSizeChanged
 * @returns {JSX.Element|null}
 * @constructor
 */
export function DataListPager({paging, onPreviousPageClick, onNextPageClick, onPageSizeChanged}) {
    if (!paging?.totalPages || !paging?.page) {
        return null;
    }
    const maxAmount = paging.page * paging.pageSize;
    return <div className={`data-list-pager`}>
        <div className={'records-info'}>
            Record {((paging.page - 1) * paging.pageSize) + 1} - {paging.total < maxAmount ? paging.total : maxAmount} of {paging.total}
        </div>
        <div className={'page-shifter'}>
            <Button icon={ArrowLeftIcon} onClick={onPreviousPageClick} disabled={paging.page <= 1} />
            <div>
                {paging.page} - {paging.totalPages}
            </div>
            <Button icon={ArrowRightIcon} onClick={onNextPageClick} disabled={paging.totalPages <= paging.page} />
        </div>
        <div className={"page-size-shifter"}>
            <Button onClick={() => onPageSizeChanged(10)} className={ paging.pageSize === 10 ? "btn--active" : "" }>10</Button> |
            <Button onClick={() => onPageSizeChanged(50)} className={ paging.pageSize === 50 ? "btn--active" : "" }>50</Button> |
            <Button onClick={() => onPageSizeChanged(100)} className={ paging.pageSize === 100 ? "btn--active" : "" }>100</Button>
        </div>
    </div>;
}
