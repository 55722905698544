import React, {useState} from "react";
import {Button, ButtonTypes} from "../button/button";
import {FormGroup} from "../form-group/form-group";
import {InvoiceLine} from "../../models/invoice-line";

export function InvoiceLineForm({
                                    invoiceLine,
                                    defaultUnitPrice,
                                    vatApplicable,
                                    defaultUnit,
                                    defaultVatPercentage,
                                    onCancel,
                                    onSave
                                }) {
    const [description, setDescription] = useState(invoiceLine?.description || "");
    const [unitPrice, setUnitPrice] = useState(invoiceLine?.unitPrice || defaultUnitPrice || 0);
    const [unit, setUnit] = useState(invoiceLine?.unit || defaultUnit || "");
    const [vatPercentage, setVatPercentage] = useState(invoiceLine?.vatPercentage || vatApplicable ? (defaultVatPercentage || 21) : null);
    const [amount, setAmount] = useState(invoiceLine?.amount || 0);
    const handleInput = (set, e) => {
        set(e.target.value);
    };
    const handleSaveClicked = async (e) => {
        if(invoiceLine?.index > 0) {
            invoiceLine.description = description;
            invoiceLine.unitPrice = unitPrice;
            invoiceLine.unit = unit;
            invoiceLine.vatPercentage = vatPercentage;
            invoiceLine.amount = amount;
            onSave && onSave(e, invoiceLine);
        } else {
            const invoiceLineToSave = new InvoiceLine({
                index: invoiceLine?.index,
                description,
                unitPrice,
                unit,
                vatPercentage,
                amount,
            });
            // console.log("Saving invoice line", invoiceLineToSave);
            onSave && onSave(e, invoiceLineToSave);
        }
    }
    const handleCancelClicked = (e) => {
        onCancel && onCancel(e);
    };

    return (
        <form>
            <div className={"form-body"}>
                <FormGroup label={"Description"} id={"description"} input={
                    <input id="description" value={description} onChange={(e) => handleInput(setDescription, e)}
                           type="text"/>
                }/>
                <FormGroup label={"Unit"} id={"unit"} input={
                    <input id="unit" value={unit} onChange={(e) => handleInput(setUnit, e)} type="text"/>
                }/>
                <FormGroup label={"Unit price"} id={"unitPrice"} input={
                    <input id="unitPrice" value={unitPrice} onChange={(e) => handleInput(setUnitPrice, e)}
                           type="number"/>
                }/>
                {vatApplicable &&
                    <FormGroup label={"VAT Percentage"} id={"vatPercentage"} input={
                        <input id="vatPercentage" value={vatPercentage}
                               onChange={(e) => handleInput(setVatPercentage, e)} type="number"/>
                    }/>
                }
                <FormGroup label={"Amount"} id={"amount"} input={
                    <input id="amount" value={amount} onChange={(e) => handleInput(setAmount, e)} type="number"/>
                }/>
            </div>
            <div className={"form-actions"}>
                <Button buttonType={ButtonTypes.Text} onClick={handleCancelClicked}>
                    Cancel
                </Button>
                <Button buttonType={ButtonTypes.Primary} onClick={handleSaveClicked}>
                    {invoiceLine?.index > 0 ? "Save" : "Create"}
                </Button>
            </div>
        </form>
    );
}
