import React, {useState} from "react";
import {Button, ButtonTypes} from "../button/button";
import {FormGroup} from "../form-group/form-group";
import {FileUpload} from "../file-upload/file-upload";
import {bankTransactionsService} from "../../services/bank-transactions";

export function UploadBankTransactionForm({onSaved, onSaveError}) {
    const [errorMessage, setErrorMessage] = useState("");
    const [selectedFile, setSelectedFile] = useState(null);

    const handleSaveClicked = async (e) => {
        try {
            // Request made to the backend api
            // Send formData object
            const response = await bankTransactionsService.upload(selectedFile);
            if (response) {
                clearForm();
                onSaved && onSaved(e, response);
            } else {
                onSaveError && onSaveError(e, response);
            }
        } catch (err) {
            if (err.validationErrors?.length > 0) {
                setErrorMessage(err.validationErrors[0].message)
            } else {
                setErrorMessage(err.message);
            }
        }
    };

    function clearForm() {
        setSelectedFile(null);
    }

    const handleDocumentLinked = async (e) => {
        clearForm();
    }

    return (
        <form>
            {errorMessage &&
                <div className={"form-error"}>
                    {errorMessage}
                </div>
            }
            <div className={"form-body form-body--full-width"}>
                <FormGroup label={"File"} id={"file"} input={
                    <FileUpload onUpload={handleDocumentLinked} selectedFile={selectedFile}
                                setSelectedFile={setSelectedFile}/>
                }/>
            </div>
            <div className={"form-actions"}>
                {selectedFile &&
                    <Button buttonType={ButtonTypes.Primary} onClick={handleSaveClicked}>
                        Upload
                    </Button>
                }
            </div>
        </form>
    );
}
