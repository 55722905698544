import {Button} from "../button/button";
import {ArrowDownIcon, ArrowUpIcon} from "../../icons/icons";

export function DataListField({isHeader, fieldName, canSort, orderBy, children, onSortChanged}) {
    const isSortedAsc = isHeader && canSort && orderBy === fieldName;
    const isSortedDesc = isHeader && canSort && orderBy === `-${fieldName}`;
    return (
        <div className={`data-list-field data-list-field--${fieldName} ${isHeader ? "data-list-field--header" : ""} ${canSort ? "data-list-field--sortable" : ""}`}>
            {children}
            {isHeader && canSort && <div className={`data-list-field-sort`}>
                <Button onClick={() => onSortChanged && onSortChanged(fieldName, false)} icon={ArrowUpIcon} disabled={isSortedAsc} className={isSortedAsc ? "btn--active" : ""} />
                <Button onClick={() => onSortChanged && onSortChanged(fieldName, true)} icon={ArrowDownIcon} disabled={isSortedDesc} className={isSortedDesc ? "btn--active" : ""} />
            </div>}
        </div>
    );
}
