import {DataListFilter} from "./data-list-filter";
import {DataListField} from "./data-list-field";
import {DataListPager} from "./data-list-pager";
import "./data-list.scss";
import DragAndDrop from "../drag-and-drop/drag-and-drop";

export function NewDataList({
                                configuration: {
                                    data,
                                    name,
                                    columnDefinitions,
                                    headerActions,
                                    rowActions,
                                    dataRowClassName,
                                    filters,
                                    onPageChanged,
                                    onPageSizeChanged,
                                    onSortChanged,
                                    enableDragDrop,
                                    onRowDrop
                                }
                            }) {

    const handleOnNextPageClick = async () => {
        onPageChanged && onPageChanged({
            page: data.paging.page + 1,
            pageSize: data.paging.pageSize,
            orderBy: data.orderBy
        });
    };
    const handleOnPreviousPageClick = async () => {
        onPageChanged && onPageChanged({
            page: data.paging.page - 1,
            pageSize: data.paging.pageSize,
            orderBy: data.orderBy
        });
    };
    const handlePageSizeChanged = async (pageSize) => {
        onPageSizeChanged && onPageSizeChanged({page: 1, pageSize, orderBy: data.orderBy});
    }
    const handleSortChanged = async (field, isDesc) => {
        onSortChanged && onSortChanged({
            page: data.paging.page,
            pageSize: data.paging.pageSize,
            orderBy: `${isDesc ? "-" : ""}${field}`
        });
    }

    const renderTableHeaders = () => {
        return <>
            {columnDefinitions.map(col =>
                <DataListField isHeader={true}
                               key={col.name}
                               fieldName={col.name}
                               canSort={col.canSort}
                               orderBy={data.orderBy}
                               onSortChanged={handleSortChanged}
                >
                    {col.title}
                </DataListField>
            )}
            {(rowActions?.length > 0 || headerActions?.length > 0) &&
                <DataListField fieldName="actions" isHeader={true}>
                    {headerActions?.map((action, index) =>
                        <div key={index} className={"data-list-row-action"}>
                            {action(data?.results)}
                        </div>
                    )}
                    &nbsp;
                </DataListField>
            }
        </>;
    }

    const renderTableDataFields = (row, rowIdx) => {
        return <>
            {columnDefinitions.map(col =>
                <DataListField key={col.name} fieldName={col.name}>
                    {col.render(row[col.name], row, rowIdx, col)}
                </DataListField>
            )}
            {rowActions?.length > 0 &&
                <DataListField fieldName="actions">
                    {rowActions.map((action, index) =>
                        <div key={index} className={"data-list-row-action"}>
                            {action(row, index)}
                        </div>
                    )}
                </DataListField>
            }
        </>;
    }

    const getDataRowClassName = (rowData) => {
        return `data-row ${dataRowClassName ? `data-row-${dataRowClassName(rowData)}` : ""}`;
    }

    return (
        <div className={`data-list data-list--${name}`}>
            <DataListFilter filters={filters}/>
            <div className={"data-list-grid"}>
                <div className={"data-row"}>
                    {renderTableHeaders()}
                </div>
                {data?.results?.length > 0 && data.results.map((row, rowIdx) =>
                    enableDragDrop ?
                        <DragAndDrop className={getDataRowClassName(row)} key={rowIdx} namy={rowIdx} onDrop={(files) => onRowDrop(row, files)}>
                            {renderTableDataFields(row, rowIdx)}
                        </DragAndDrop> :
                        <div className={getDataRowClassName(row)} key={rowIdx}>
                            {renderTableDataFields(row, rowIdx)}
                        </div>
                )}
            </div>
            {data?.paging &&
                <DataListPager paging={data.paging} onNextPageClick={handleOnNextPageClick}
                               onPreviousPageClick={handleOnPreviousPageClick}
                               onPageSizeChanged={handlePageSizeChanged}
                />
            }
        </div>
    )
}
