import {CloseIcon} from "../../icons/icons";
import "./modal.scss";
import {forwardRef, useImperativeHandle, useRef} from "react";
import {Button} from "../button/button";

export const ModalSizes = {
    max: "max-size"
}
export const Modal = forwardRef(({title, size, onClose, onShow, actions, children}, ref) => {
    const modalWrapperRef = useRef();
    const show = () => {
        onShow && onShow();
    };
    const close = () => {
        onClose && onClose();
    }
    useImperativeHandle(ref, () => ({
        show,
        close,
    }));
    return (
        <div ref={modalWrapperRef} className={`modal-wrapper modal-wrapper--visible`}>
            <div className={`modal ${size ? `modal--${size}` : ""}`}>
                <div className="modal-header">
                    <h2>{title}</h2>
                    <div className={"modal-actions"}>
                        {actions?.length > 0 && actions.map((a, index) =>
                            <div key={index}>
                                {a}
                            </div>
                        )}
                        <Button icon={CloseIcon} onClick={() => close()} />
                    </div>
                </div>
                <div className="modal-body">
                    {children}
                </div>
            </div>
        </div>
    );
});
