import "./button.scss";
import {Link} from "react-router-dom";

export const ButtonTypes = {
    Primary: "primary",
    Secondary: "secondary",
    Tertiary: "tertiary",
    Neutral: "neutral",
    Transparent: "transparent",
    Text: "text"
};

export function Button({className, buttonType, onClick, icon, disabled, children}) {
    const handleClick = (e) => {
        if(!disabled) {
            onClick && onClick(e);
        }
    }
    const iconOnly = (children?.length || 0) === 0;
    return (
        <div onClick={handleClick} className={`btn ${className} btn--${buttonType ?? ButtonTypes.Neutral} ${iconOnly ? "btn--icon-only" : ""} ${disabled ? "btn--disabled" : ""}`}>
            {icon &&
                <div className="btn-icon">
                    {icon()}
                </div>
            }
            {children &&
                <div className="btn-text">
                    {children}
                </div>
            }
        </div>
    )
}

export function LinkButton({href, to, buttonType, icon, disabled, children, target}) {
    const iconOnly = (children?.length || 0) === 0;
    const getLinkBody = () => {
        return <>
            {icon &&
                <div className="btn-icon">
                    {icon()}
                </div>
            }
            {children &&
                <div className="btn-text">
                    {children}
                </div>
            }
        </>
    }
    return (
        href ? (
        <a href={href} target={target} className={`btn btn--${buttonType ?? ButtonTypes.Neutral} ${iconOnly ? "btn--icon-only" : ""} ${disabled ? "btn--disabled" : ""}`}>
            {getLinkBody()}
        </a>
        ) : (
            <Link to={to} className={`btn btn--${buttonType ?? ButtonTypes.Neutral} ${iconOnly ? "btn--icon-only" : ""}`}>
                {getLinkBody()}
            </Link>
        )
    )
}
