import {Customer} from "../models/customer";
import {httpService} from "./http-service";

class CustomersService {
    get _apiUrl() {
        return `${process.env.REACT_APP_INVOCING_API_URL}/api/customers`;
    }

    async getAll() {
        const customers = await httpService.get(`${this._apiUrl}/all`);
        return customers.map(c => new Customer(c));
    }

    async search({page, pageSize, query, orderBy} = {page: 1, pageSize: 10, query: "", orderBy: "name"}) {
        const queryParams = new URLSearchParams({
            page, pageSize, query, orderBy
        });
        const url = new URL(this._apiUrl);
        url.search = queryParams.toString();
        const customerResults = await httpService.get(url);
        return {
            ...customerResults,
            results: customerResults.results.map(i => new Customer(i)),
        };
    }

    async getById(id) {
        const customer = await httpService.get(`${this._apiUrl}/${id}`);
        return new Customer(customer);
    }

    async delete(id) {
        await httpService.delete(`${this._apiUrl}/${id}`);
    }

    async saveCustomer(customer) {
        if (customer._id) {
            return await httpService.put(`${this._apiUrl}/${customer._id}`, customer);

        } else {
            return await httpService.postJson(`${this._apiUrl}`, customer);
        }
    }
}

const instance = new CustomersService();
export {instance as customersService};
