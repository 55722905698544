import {useState} from "react";
import "./drag-and-drop.scss";

function DragAndDrop({className, onDrop, children, namy}) {
    const [dragging, setDragging] = useState(false);
    const [dragCounter, setDragCounter] = useState(0);

    const handleDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
    }
    const handleDragEnter = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragCounter(dragCounter + 1);
        if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
            setDragging(true);
        }
    }
    const handleDragLeave = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragCounter(dragCounter - 1);
        if (dragCounter - 1 > 0) return;
        setDragging(false);
    }
    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragging(false);
        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            onDrop(e.dataTransfer.files)
            e.dataTransfer.clearData();
            setDragCounter(0);
        }
    }

    return (
        <div className={`draggable ${dragging ? "draggable--dragging" : ""}`} onDragEnter={handleDragEnter}
             onDragLeave={handleDragLeave} onDragOver={handleDragOver} onDrop={handleDrop}>
            {dragging &&
                <div className={"overlay"}>
                    <div className={"overlay-content"}>
                        Drop here
                    </div>
                </div>
            }
            <div className={className}>
                {children}
            </div>
        </div>
    );
}

export default DragAndDrop;
