import {Customer} from "./customer";
import {InvoiceLine} from "./invoice-line";

export class Invoice {
    constructor(i) {
        this._id = i?._id;
        this.number = i?.number;
        this.date = i?.date ? new Date(i.date) : new Date(Date.now());
        this.customer = i?.customer ? new Customer(i.customer) : null;
        this.lines = (i.lines || []).map(il => new InvoiceLine(il));
        this.totalPrice = i?.totalPrice || 0;
        this.template = i?.template || "";
        this.tokenDictionary = i?.tokenDictionary || null;
        this.fileId = i?.fileId || null;
    }
}
