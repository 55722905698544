import * as React from 'react'
import {Navigate, Route, Routes} from 'react-router-dom'

import {useKeycloak} from '@react-keycloak/web'
import InvoicesRoutes from "./invoices";
import CustomersRoutes from "./customers";
import PrivateRoute from "./private-route";
import BankTransactionsRoutes from "./bank-transactions";

export const AppRouter = () => {
    const {initialized} = useKeycloak()

    if (!initialized) {
        return <div>Loading...</div>
    }

    return (
        <Routes>
            <Route path="/" element={<Navigate to="/invoices"/>}/>
            <Route path="invoices/*" element={
                <PrivateRoute role="finance-manager">
                    <InvoicesRoutes/>
                </PrivateRoute>
            }/>
            <Route path="customers/*" element={
                <PrivateRoute role="finance-manager">
                    <CustomersRoutes/>
                </PrivateRoute>
            }/>
            <Route path="bank-transactions/*" element={
                <PrivateRoute role="finance-manager">
                    <BankTransactionsRoutes/>
                </PrivateRoute>
            }/>
        </Routes>
    )
}
