import React, {useEffect, useState} from "react";
import {Button, ButtonTypes} from "../button/button";
import {FormGroup} from "../form-group/form-group";
import {FileUpload} from "../file-upload/file-upload";
import {bankTransactionsService} from "../../services/bank-transactions";

export function LinkBankTransactionForm({transactionId, onCancel, onSaved, onSaveError}) {
    const [errorMessage, setErrorMessage] = useState("");
    const [selectedFile, setSelectedFile] = useState(null);
    const [name, setName] = useState("");

    useEffect(() => {
        if(!name && selectedFile) {
            setName(selectedFile?.name || "")
        }
    }, [name, selectedFile]);

    const handleSaveClicked = async (e) => {
        try {
            // Request made to the backend api
            // Send formData object
            const response = await bankTransactionsService.linkInvoice(transactionId, selectedFile, name);
            if (response) {
                clearForm();
                onSaved && onSaved(e, response);
            } else {
                onSaveError && onSaveError(e, response);
            }
        } catch (err) {
            if (err.validationErrors?.length > 0) {
                setErrorMessage(err.validationErrors[0].message)
            } else {
                setErrorMessage(err.message);
            }
        }
    };
    const handleCancelClicked = (e) => {
        onCancel && onCancel(e);
    };

    function clearForm() {
        setSelectedFile(null);
        setName("");
    }
    const handleDocumentLinked = async (e) => {
        clearForm();
    }

    return (
        <form>
            {errorMessage &&
                <div className={"form-error"}>
                    {errorMessage}
                </div>
            }
            <div className={"form-body form-body--full-width"}>
                <FormGroup label={"File"} id={"file"} input={
                    <FileUpload onUpload={handleDocumentLinked} selectedFile={selectedFile} setSelectedFile={setSelectedFile}/>
                }/>
                <FormGroup label={"Name"} id={"name"} input={
                    <input id="name" value={name} onChange={(e) => setName(e.target.value)} type="text"/>
                }/>
            </div>
            <div className={"form-actions"}>
                <Button buttonType={ButtonTypes.Text} onClick={handleCancelClicked}>
                    Cancel
                </Button>
                <Button buttonType={ButtonTypes.Primary} onClick={handleSaveClicked}>
                    Link
                </Button>
            </div>
        </form>
    );
}
