import keycloak from "../keycloak";

class HttpService {

    async get(url) {
        const response = await this.doFetch(url);
        return this.handleResponse(response);
    }

    async download(url) {
        const response = await this.doFetch(url);
        if(response.status < 300) {
        const blob = await response.blob();
        const fileName = this.extractFileNameFromContentDisposition(response.headers.get("Content-Disposition"));
        return {blob, fileName};
        }
    }

    async downloadMultiple(url, fileIds, fileName) {
        const response = await this.doFetch(url, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                fileIds, fileName
            })
        });
        if(response.status < 300) {
            const blob = await response.blob();
            const fileName = this.extractFileNameFromContentDisposition(response.headers.get("Content-Disposition"));
            return {blob, fileName};
        }
    }

    extractFileNameFromContentDisposition(disposition) {
        let filename = "";
        if (disposition && disposition.indexOf('attachment') !== -1) {
            const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            const matches = filenameRegex.exec(disposition);
            if (matches != null && matches[1]) {
                filename = matches[1].replace(/['"]/g, '');
            }
        }
        return filename;
    }

    async delete(url) {
        const response = await this.doFetch(url, {
            method: "DELETE"
        });
        return this.handleResponse(response);
    }

    async postJson(url, body) {
        const response = await this.doFetch(url, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        });
        return this.handleResponse(response);
    }

    async uploadFile(url, file, name) {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("fileName", name || file.name);
        const response = await this.doFetch(
                url, {
                    method: "POST",
                    body: formData
                }
            );
        return this.handleResponse(response);
    }

    async put(url, body) {
        const response = await this.doFetch(url, {
            method: "PUT",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        });
        return this.handleResponse(response);
    }

    async doFetch(url, options) {
        return await fetch(url, {
            ...options,
            headers: {
                ...options?.headers,
                Authorization: `Bearer ${keycloak.token}`
            }
        })
    }

    async handleResponse(response) {
        const responseBody = await response.json();
        if (response.status === 200) {
            return responseBody;
        } else {
            throw responseBody;
        }
    }
}

const instance = new HttpService();
export {instance as httpService}
