import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import {ListIcon} from "../../icons/icons";
import "./create-invoice.scss";
import {ButtonTypes, LinkButton} from "../../components/button/button";
import {invoicesService} from "../../services/invoices";
import {InvoiceForm} from "../../components/forms/invoice";

export function CreateInvoice() {
    let navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState("");

    const handleSaveInvoice = async (invoice) => {
        try {
            const response = await invoicesService.create(invoice);
            if (response) {
                navigate("/invoices");
            } else {
                setErrorMessage("An error occurred trying to save the invoice");
            }
        } catch (err) {
            if (err.validationErrors?.length > 0) {
                setErrorMessage(err.validationErrors[0].message)
            } else {
                setErrorMessage(err.message);
            }
        }
    };

    return (
        <>
            <div className="page-header">
                <h2>Create invoice</h2>
                <div className="page-header-actions">
                    <LinkButton to={"/invoices"} buttonType={ButtonTypes.Primary} icon={ListIcon}>
                        List
                    </LinkButton>
                </div>
            </div>
            {errorMessage &&
                <div className={"form-error"}>
                    {errorMessage}
                </div>
            }
            <InvoiceForm onSave={handleSaveInvoice} />
        </>
    );
}
