import logo from "../../logo-dark-mode.svg";
import {Button, LinkButton} from "../button/button";
import {useKeycloak} from "@react-keycloak/web";

import "./header.scss";

export const Header = () => {
    const {keycloak} = useKeycloak();
    return (
        <header className="app-header">
            <img src={logo} className="app-logo" alt="logo"/>
            <h1>Voice them in</h1>
            <nav className={"main-nav"}>
                {!keycloak.authenticated && (
                    <Button onClick={() => keycloak.login()}>
                        Login
                    </Button>
                )}
                {!!keycloak.authenticated && <>
                    <LinkButton to={`/invoices`}>Invoices</LinkButton>
                    <LinkButton to={`/customers`}>Customers</LinkButton>
                    <LinkButton to={`/bank-transactions`}>Transactions</LinkButton>
                    <Button onClick={() => keycloak.logout()}>
                        Logout ({keycloak.tokenParsed.preferred_username})
                    </Button>
                </>}
            </nav>
        </header>
    )
}
