import {httpService} from "./http-service";

class FilesService {
    get _apiUrl() {
        return `${process.env.REACT_APP_INVOCING_API_URL}/api/files`;
    }

    async download(fileId) {
        return await httpService.download(`${this._apiUrl}/${fileId}/download`);
    }

    async downloadMultiple(fileIds, fileName) {
        return await httpService.downloadMultiple(`${this._apiUrl}/download-multiple`, fileIds, fileName);
    }

    /**
     * @param {{blob: Blob, fileName: string}} file
     */
    downloadFile(file) {
        const link = window.URL.createObjectURL(file.blob);
        const a = document.createElement("a");
        a.setAttribute("download", file.fileName);
        a.setAttribute("href", link);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }
}
const instance = new FilesService();
export {instance as filesService}
