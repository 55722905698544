import {useNavigate} from "react-router-dom";
import {ButtonTypes, LinkButton} from "../../components/button/button";
import {ListIcon} from "../../icons/icons";
import {UploadBankTransactionForm} from "../../components/forms/upload-bank-transaction";

function UploadTransactions() {
    const navigate = useNavigate();
    const onSaved = () => {
        navigate("/bank-transactions");
    };
    return (
        <>
            <div className="page-header">
                <h2>Upload transactions</h2>
                <div className="page-header-actions">
                    <LinkButton to={"/bank-transactions"} buttonType={ButtonTypes.Primary} icon={ListIcon}>
                        List
                    </LinkButton>
                </div>
            </div>
            <UploadBankTransactionForm onSaved={onSaved} />
        </>
    );
}

export default UploadTransactions;
