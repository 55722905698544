import {useCallback, useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {invoicesService} from "../../services/invoices";
import {InvoiceForm} from "../../components/forms/invoice";
import {ButtonTypes, LinkButton} from "../../components/button/button";
import {ListIcon} from "../../icons/icons";

function InvoiceDetails() {
    const {invoiceNumber} = useParams();
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState("");
    const [invoice, setInvoice] = useState(null);

    const fetchData = useCallback(async () => {
        const invoice = await invoicesService.getByNumber(invoiceNumber);
        setInvoice(invoice);
    }, [invoiceNumber]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const handleSaveInvoice = async (invoiceToSave) => {
        try {
            invoiceToSave._id = invoice._id;
            await invoicesService.update(invoiceToSave);
            navigate("/invoices");
        } catch (err) {
            if (err.validationErrors?.length > 0) {
                setErrorMessage(err.validationErrors[0].message)
            } else {
                setErrorMessage(err.message);
            }
        }
    }
    const handleDeleteInvoice = async () => {
        try {
            await invoicesService.delete(invoice._id);
            navigate("/invoices");
        } catch (err) {
            if (err.validationErrors?.length > 0) {
                setErrorMessage(err.validationErrors[0].message)
            } else {
                setErrorMessage(err.message);
            }
        }
    }
    return (
        <>
            {invoice &&
                <>
                    <div className="page-header">
                        <h2>Edit invoice - {invoice.number}</h2>
                        <div className="page-header-actions">
                            <LinkButton to={"/invoices"} buttonType={ButtonTypes.Primary} icon={ListIcon}>
                                List
                            </LinkButton>
                        </div>
                    </div>
                    {errorMessage &&
                        <div className={"form-error"}>
                            {errorMessage}
                        </div>
                    }
                    <InvoiceForm invoice={invoice} onSave={handleSaveInvoice} onDelete={handleDeleteInvoice}/>
                </>
            }
        </>
    )
}

export default InvoiceDetails;
