export class Customer {
    constructor(c) {
        this._id = c?._id || c?.id;
        this.name = c?.name || "";
        this.vatNumber = c?.vatNumber || "";
        this.vatApplicable = c?.vatApplicable || false;
        this.addressLines = c?.addressLines || [];
        this.defaultUnitPrice = Number(c?.defaultUnitPrice || 0);
        this.defaultUnit = c?.defaultUnit || "Uur";
        this.defaultVatPercentage = c?.defaultVatPercentage || 21;
    }
}
