export class InvoiceLine {
    constructor(il) {
        this.index = Number(il?.index || 0);
        this.description = il?.description;
        this.amount = Number(il?.amount || 0);
        this.unit = il?.unit || "";
        this.vatPercentage = Number(il?.vatPercentage || 0);
        this.unitPrice = Number(il?.unitPrice || 0);
        this.totalPrice = this.amount * this.unitPrice;
    }
}
