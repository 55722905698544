import {Route, Routes} from "react-router-dom";
import ManageCustomer from "../pages/customers/manage-customer";
import CustomersList from "../pages/customers/customers-list";

function CustomersRoutes() {
    return (
        <Routes>
            <Route path=":customerId" element={<ManageCustomer />} />
            <Route path="new" element={<ManageCustomer />} />
            <Route path="" element={<CustomersList />} />
        </Routes>
    );
}

export default CustomersRoutes;
