import {customersService} from "../../services/customers";
import {Invoice} from "../../models/invoice";
import {Button, ButtonTypes, LinkButton} from "../button/button";
import {AddIcon, EditIcon, RemoveIcon} from "../../icons/icons";
import {FormGroup} from "../form-group/form-group";
import {Modal} from "../modal/modal";
import {CustomerForm} from "./customer";
import {InvoiceLineForm} from "./invoice-line";
import {useCallback, useEffect, useState} from "react";

export function InvoiceForm({invoice, onSave, onDelete}) {
    const [date, setDate] = useState((invoice?.date || new Date(Date.now())).toISOString().substr(0, 10));
    const [customer, setCustomer] = useState(invoice?.customer || null);
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [invoiceLines, setInvoiceLines] = useState(invoice?.lines || []);
    const [invoiceLineToEdit, setInvoiceLineToEdit] = useState(null);
    const [showCustomerModal, setShowCustomerModal] = useState(false);
    const [showInvoiceLineModal, setShowInvoiceLineModal] = useState(false);
    const [customers, setCustomers] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);

    const fetchData = useCallback(async () => {
        const customers = await customersService.getAll();
        setCustomers(customers);
        setIsLoaded(true);
    }, [])

    useEffect(() => {
        if (customers?.length > 0 && !selectedCustomer) {
            if(!customer) {
                setCustomer(invoice?.customer?._id || customers[0]._id);
            }
            if(!selectedCustomer) {
                setSelectedCustomer(invoice?.customer || customers[0]);
            }
        }
    }, [customer, customers, selectedCustomer, invoice]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const handleAddCustomerClicked = (e) => {
        setShowCustomerModal(true);
        e.preventDefault();
    };

    const handleCustomerSaved = async (e) => {
        setShowCustomerModal(false);
        await fetchData();
    }

    const handleEditInvoiceLineClicked = (invoiceLine, index) => {
        setInvoiceLineToEdit(invoiceLine);
        setShowInvoiceLineModal(true);
    }
    const handleAddInvoiceLineClicked = () => {
        setInvoiceLineToEdit(null);
        setShowInvoiceLineModal(true);
    }
    const removeInvoiceLine = (idx) => {
        invoiceLines.splice(idx, 1);
        invoiceLines.forEach((il, index) => {
            if(index >= idx) {
                il.index--;
            }
        })
        setInvoiceLines([...invoiceLines]);
    }
    const handleInvoiceLineSaved = (e, invoiceLine) => {
        if (invoiceLine.index <= 0) {
            invoiceLine.index = invoiceLines.length + 1;
            invoiceLines.push(invoiceLine);
        }
        setInvoiceLines([...invoiceLines]);
        setShowInvoiceLineModal(false);
    }
    const handleDateChange = (e) => {
        setDate(e.target.value);
    }
    const handleCustomerChange = (e) => {
        setCustomer(e.target.value);
        setSelectedCustomer(customers.filter(c => c._id === e.target.value)[0]);

    }
    const handleSaveClicked = async (e) => {
        const invoiceToSave = new Invoice({
            date,
            customer: selectedCustomer,
            lines: invoiceLines
        });
        onSave && onSave(invoiceToSave);
    };
    const handleDeleteClicked = async () => {
        onDelete && onDelete();
    }

    if (!isLoaded) return null;
    return (
        <>
            <form>
                <div className={"form-body"}>
                    <FormGroup label={"Date"} id={"date"} input={
                        <input id="date" type="date" value={date} onChange={handleDateChange}/>
                    }/>
                    <FormGroup label={"Customer"} id={"customer"} input={
                        <select id="customer" value={customer?._id} onChange={handleCustomerChange}>
                            {customers.map(c => <option key={c._id} value={c._id}>{c.name}</option>)}
                        </select>
                    } action={<Button icon={AddIcon}/>} onAction={handleAddCustomerClicked}/>
                </div>
            </form>
            <div className={"invoice-lines"}>
                <h3>Invoice lines</h3>
                <div className={"invoice-line invoice-line--header"}>
                    <div className={"invoice-line-index"}>
                        #
                    </div>
                    <div className={"invoice-line-description"}>
                        Description
                    </div>
                    <div className={"invoice-line-price"}>
                        Price
                    </div>
                    <div className={"invoice-line-amount"}>
                        Qty.
                    </div>
                    <div className={"invoice-line-total"}>
                        Total
                    </div>
                    <div className={"invoice-line-actions"}>
                    </div>
                </div>
                {invoiceLines.map((line, idx) => (
                    <div key={idx} className={"invoice-line"}>
                        <div className={"invoice-line-index"}>
                            {line.index}
                        </div>
                        <div className={"invoice-line-description"}>
                            {line.description}
                        </div>
                        <div className={"invoice-line-price"}>
                            &euro;{line.unitPrice.toLocaleString()}
                        </div>
                        <div className={"invoice-line-amount"}>
                            {line.amount}
                        </div>
                        <div className={"invoice-line-total"}>
                            &euro;{line.totalPrice.toLocaleString()}
                        </div>
                        <div className={"invoice-line-actions"}>
                            <Button icon={EditIcon} onClick={() => handleEditInvoiceLineClicked(line, idx)}/>
                            <Button icon={RemoveIcon} onClick={() => removeInvoiceLine(idx)}/>
                        </div>
                    </div>
                ))}
                <Button className={"btn--add-invoice-line"} icon={AddIcon}
                        onClick={handleAddInvoiceLineClicked}>Add line</Button>
            </div>
            <div className={"form-actions"}>
                <LinkButton to={"/invoices"}>
                    Cancel
                </LinkButton>
                {invoice &&
                    <Button buttonType={ButtonTypes.Secondary} onClick={handleDeleteClicked}>
                        Delete
                    </Button>
                }
                <Button buttonType={ButtonTypes.Primary} onClick={handleSaveClicked}>
                    {invoice ? "Save" : "Create"}
                </Button>
            </div>
            {showCustomerModal &&
                <Modal title={"Add customer"} onClose={() => setShowCustomerModal(false)}>
                    <CustomerForm onCancel={() => setShowCustomerModal(false)} onSaved={handleCustomerSaved}/>
                </Modal>
            }
            {showInvoiceLineModal &&
                <Modal title={"Add invoice line"} onClose={() => setShowInvoiceLineModal(false)}>
                    <InvoiceLineForm invoiceLine={invoiceLineToEdit}
                                     defaultUnitPrice={selectedCustomer?.defaultUnitPrice}
                                     defaultUnit={selectedCustomer?.defaultUnit}
                                     vatApplicable={selectedCustomer?.vatApplicable}
                                     defaultVatPercentage={selectedCustomer?.defaultVatPercentage}
                                     onCancel={() => setShowInvoiceLineModal(false)}
                                     onSave={handleInvoiceLineSaved}/>
                </Modal>
            }
        </>
    );
}
