export function FormGroup({id, label, input, action, onAction}) {
    return (
        <div className="form-group">
            <label htmlFor={id}>{label}</label>
            <div className={"form-input"}>
                {input}
                {action &&
                    <div className={"form-group-actions"} onClick={onAction}>
                        {action}
                    </div>
                }
            </div>
        </div>
    );
}
