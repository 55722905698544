import {CustomerForm} from "../../components/forms/customer";
import {useEffect, useState} from "react";
import {customersService} from "../../services/customers";
import {useNavigate, useParams} from "react-router-dom";
import {ButtonTypes, LinkButton} from "../../components/button/button";
import {ListIcon} from "../../icons/icons";
import {Customer} from "../../models/customer";

function ManageCustomer() {
    const navigate = useNavigate();
    const {customerId} = useParams();
    const [customer, setCustomer] = useState(null);
    useEffect(() => {
        if (customerId) {
            async function fetchCustomer() {
                const fetchedCustomer = await customersService.getById(customerId);
                setCustomer(fetchedCustomer);
            }

            fetchCustomer();
        } else {
            setCustomer(new Customer());
        }
    }, [customerId]);
    return (
        <>
            <div className="page-header">
                <h2>Customer details</h2>
                <div className="page-header-actions">
                    <LinkButton to={"/customers"} buttonType={ButtonTypes.Primary} icon={ListIcon}>
                        List
                    </LinkButton>
                </div>
            </div>
            {customer &&
                <CustomerForm customer={customer} onSaved={() => navigate("/customers")}
                              onDelete={() => navigate("/customers")} onCancel={() => navigate("/customers")}/>
            }
        </>
    )
}

export default ManageCustomer;
