export class BankTransaction {
    constructor(c) {
        this._id = c?._id || c?.id;
        this.uniqueIdentifier = c?.uniqueIdentifier;
        this.accountNumber = c?.accountNumber;
        this.rubricName = c?.rubricName;
        this.name = c?.name;
        this.currency = c?.currency;
        this.copyNumber = c?.copyNumber;
        this.date = c?.date ? new Date(c.date) : null;
        this.originalDate = c?.originalDate;
        this.description = c?.description;
        this.valuta = c?.valuta;
        this.amount = Number(c?.amount) || null;
        this.balance = Number(c?.balance) || null;
        this.credit = Number(c?.credit) || null;
        this.debit = Number(c?.debit) || null;
        this.isHandled = c?.isHandled || false;
        this.counterParty = new CounterParty(c?.counterParty);
        this.freeAnnouncement = c?.freeAnnouncement;
        this.structuredAnnouncement = c?.structuredAnnouncement;
        this.fileIds = c?.fileIds || [];
    }
}

export class CounterParty {
    constructor(c) {
        this.accountNumber = c?.accountNumber;
        this.bic = c?.bic;
        this.name = c?.name;
        this.address = c?.address;
    }
}
