/**
 *
 * @param onFilterChange
 * @returns {JSX.Element|null}
 * @constructor
 */
import {FormGroup} from "../form-group/form-group";

export function DataListFilter({filters}) {
    return filters?.length > 0 ? <div className={`data-list-filter`}>
        {filters.map((filter, index) =>
            <FormGroup id={filter.name} input={filter.element} label={filter.name} key={index}>
                {filter.element}
            </FormGroup>
        )}
        </div> : null;
}
